<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="b端生产用户id关联 {zb_user.id}平台为：0,b端用户id" prop="userId">-->
<!--                <a-input v-model="queryParam.userId" placeholder="请输入b端生产用户id关联 {zb_user.id}平台为：0,b端用户id" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <a-col :md="8" :sm="24">
              <a-form-item label="Blog Title" prop="title">
                <a-input v-model="queryParam.title" placeholder="" allow-clear/>
              </a-form-item>
            </a-col>
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="Business Type" prop="businessType">-->
<!--                <a-select v-model="queryParam.businessType" placeholder="-All Business Type-">-->
<!--                  <a-select-option :key="item.id" v-for="(item,index) in BusinessTypeList">{{ item.name }}</a-select-option>-->
<!--                </a-select>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <a-col :md="8" :sm="24">
              <a-form-item label="Status" prop="status">
                <a-select v-model="queryParam.status" placeholder="-All Business Type-">
                  <a-select-option :key="1">Enable</a-select-option>
                  <a-select-option :key="0">Disable</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="Brand" prop="businessType">
                <a-select
                  placeholder="Brand"
                  style="width: 100%"
                  showSearch
                  v-model="queryParam.sendType"
                  optionFilterProp="title"
                >
                  <a-select-option v-for="item in brandList" :key="item.id" :value="item.id" :title="item.name">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="Blog Keywords" prop="keywords">
                  <a-input v-model="queryParam.keywords" placeholder="" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />Search</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />Clear All</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['cigarette:blog:add']">-->
<!--          <a-icon type="plus" />Add-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['cigarette:blog:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['cigarette:blog:remove']">-->
<!--          <a-icon type="delete" />Delete-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['cigarette:blog:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
<!--        <table-setting-->
<!--          :style="{float: 'right'}"-->
<!--          :table-size.sync="tableSize"-->
<!--          v-model="columns"-->
<!--          :refresh-loading="loading"-->
<!--          @refresh="getList" />-->
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />

      <a-row :gutter="[16,16]">
        <a-col :span="6"  v-hasPermi="['cigarette:blog:add']">
          <div class="goodsDateAdd" @click="$refs.createForm.handleAdd()">
            <a-icon type="plus" class="goodsDateAdd-icon" style="font-size: 50px;" />
            <div class="goodsDateAdd-title">Create Blog</div>
          </div>
        </a-col>
        <a-col :span="6" v-for="(item,index) in list">
          <div class="goodsDateInfo" :style="[{
            background:item.status === 0 ? '#f8f8f8':'#fff'
          }]">
            <div class="goodsDateInfo-absolute">
              <!--              <a-icon type="eye" theme="twoTone" style="font-size: 28px;margin-left: 5px" />-->
              <a-icon type="edit" @click="$refs.createForm.handleUpdate(item, undefined)" v-hasPermi="['cigarette:blog:edit']" theme="twoTone" style="font-size: 28px;margin-left: 5px" />
            </div>
            <div class="goodsDateInfo-GoodsDate">
              <div class="goodsDateInfo-GoodsDate-flex">
                <img class="goodsDateInfo-GoodsDate-flex-img" :src="item.url" />
                <div class="goodsDateInfo-GoodsDate-flex-name">{{ item.title }}</div>
                <div style="display:flex;align-items: center;justify-content: space-between;width: 100%">
                  <div>{{item.sendTypeName}}</div>
                  <div>{{item.createTime}}</div>
                  </div>
              </div>
            </div>
<!--            <div class="goodsDateInfo-bottom">-->
<!--              <div class="goodsDateInfo-bottom-flex">-->
<!--                <a-button type="primary" @click.stop="gotoBrandSku(item)">Brand Sku</a-button>-->
<!--                <a-button type="primary" @click.stop="gotoBatchRecord(item)">Batch</a-button>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </a-col>
      </a-row>
      <!-- 数据展示 -->
<!--      <a-table-->
<!--        :loading="loading"-->
<!--        :size="tableSize"-->
<!--        rowKey="id"-->
<!--        :columns="columns"-->
<!--        :data-source="list"-->
<!--        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"-->
<!--        :pagination="false"-->
<!--        :bordered="tableBordered"-->
<!--      >-->
<!--        <span slot="createTime" slot-scope="text, record">-->
<!--          {{ parseTime(record.createTime) }}-->
<!--        </span>-->
<!--        <span slot="operation" slot-scope="text, record">-->
<!--          <a-divider type="vertical" v-hasPermi="['cigarette:blog:edit']" />-->
<!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['cigarette:blog:edit']">-->
<!--            <a-icon type="edit" />Modify-->
<!--          </a>-->
<!--          <a-divider type="vertical" v-hasPermi="['cigarette:blog:remove']" />-->
<!--          <a @click="handleDelete(record)" v-hasPermi="['cigarette:blog:remove']">-->
<!--            <a-icon type="delete" />Delete-->
<!--          </a>-->
<!--        </span>-->
<!--      </a-table>-->
      <!-- 分页 -->
<!--        show-size-changer-->
      <a-pagination
        class="ant-table-pagination"

        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageBlog,listBlog, delBlog } from '@/api/cigarette/blog'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import {listConfig} from "@/api/cigarette/config";
import {listBrand} from "@/api/cigarette/brand";

export default {
  name: 'Blog',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        title: null,
        keywords: null,
        description: null,
        businessType: null,
        blogText: null,
        sendType: null,
        url: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '${comment}',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '创建时间',
        //   dataIndex: 'createTime',
        //   scopedSlots: { customRender: 'createTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: 'b端生产用户id关联 {zb_user.id}平台为：0,b端用户id',
        //   dataIndex: 'userId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Blog Title',
          dataIndex: 'title',
          align: 'center'
        },
        {
          title: 'Blog Keywords',
          dataIndex: 'keywords',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'Blog Description',
          dataIndex: 'description',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: 'Business Type',
        //   dataIndex: 'businessType',
        //   align: 'center'
        // },
        {
          title: 'Blog text',
          dataIndex: 'blogText',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'Send To',
          dataIndex: 'sendType',
          align: 'center',
          customRender(t,r) {
            return r.sendTypeName;
          }
        },
        {
          title: 'Cover Picture',
          dataIndex: 'url',
          align: 'center'
        },
        // {
        //   title: '状态：1-开启 0-禁用',
        //   dataIndex: 'status',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Status',
          dataIndex: 'status',
          align: 'center',
          customRender(t,r) {
            if(r.status == 1) {
              return 'Enable'
            } else {
              return 'Disable'
            }
          }
        },
        {
          title: 'Operation',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      BusinessTypeList:[],
      brandList:[]
    }
  },
  filters: {
  },
  created () {
    this.getList();
    this.getListBusinessType();
    this.getBrandList();
  },
  computed: {
  },
  watch: {
  },
  methods: {
    getBrandList() {
      listBrand().then(res => {
        if (res.success) {
          this.brandList = res.data;
        }
      })
    },
    getListBusinessType () {
      listConfig({
        type: 0,
        status: 1
      }).then(res => {
        if (res.success) {
          this.BusinessTypeList = res.data;
        }
      })
    },
    /** 查询新-用户微博列表 */
    getList () {
      this.loading = true
     pageBlog(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        title: undefined,
        keywords: undefined,
        description: undefined,
        businessType: undefined,
        blogText: undefined,
        sendType: undefined,
        url: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delBlog(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('cigarette/user-blog/export', {
            ...that.queryParam
          }, `新-用户微博_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>

<style lang="less" scoped>
.goodsDateAdd {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
  border-radius: 8px;
  flex-direction: column;
}
.goodsDateAdd:hover {
  border: 1px solid blue;

  .goodsDateAdd-icon {
    color:blue;
  }

  .goodsDateAdd-title {
    color: blue;
  }
}
.goodsDateInfo {
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
  border-radius: 8px;
  flex-direction: column;
}

.goodsDateInfo-GoodsDate-flex-img {
  height: 200px;
  width: 80%;
}

.goodsDateInfo-GoodsDate-flex-name {
  width: 80%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.goodsDateInfo-absolute {
  position: absolute;
  right: 5px;
  top: 5px;
  display: none;
  z-index: 2;
}
.goodsDateInfo-GoodsDate {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.goodsDateInfo-GoodsDate-flex {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.goodsDateInfo-bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0;
  display: none;
}

.goodsDateInfo-bottom-flex {
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
}
.goodsDateInfo:hover {
  border: 1px solid blue;
  .goodsDateInfo-absolute {
    display: block;
  }

  .goodsDateInfo-bottom {
    display: block;
  }
}
</style>

